import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import firebase from './firebase';
import { StoreContainer } from './store';
import 'react-confirm-alert/src/react-confirm-alert.css';
// eslint-disable-next-line no-unused-vars
import { IDelivery } from './IDelivery';
import Alert from './components/Alert';
import Button from './components/Button';
import OrderNumberBadge from './components/OrderNumberBadge';
import CookingStatusBadge from './components/CookingStatusBadge';
import ShopNameWithManualLink from './components/ShopNameWithManualLink';
import BlankLink from './components/BlankLink';
import SlotTimeRange from './components/SlotTimeRange';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function BatchDelivery(props: { delivery: IDelivery }) {
  const [posting, setPosting] = useState(false);
  const [messageError, setMessageError] = useState('');

  const storeContainer = StoreContainer.useContainer();
  const [slot, setSlot] = useState<any>();

  const { shopId } = props.delivery;
  const shopData = storeContainer.shopData[shopId];
  const shopShortName = shopData.short_name;

  const [currentToken, setCurrentToken] = useState<string | null>(null);

    useEffect(() => {
    const unregisterMemosObserver = firebase
      .firestore()
      .collection('shops')
      .doc(shopId)
      .collection('slots')
      .doc(props.delivery.slotId)
      .onSnapshot({ includeMetadataChanges: true }, (snap) => {
        if (snap.metadata.fromCache) {
          // cache からの場合は画面を更新しない
          return;
        }
        setSlot(snap.data());
      });

    return () => {
      unregisterMemosObserver();
    };
  }, [shopId, props.delivery]);

  useEffect(() => {
    if (window.Notification?.permission === 'granted') {
      const message = firebase.messaging();
      message.getToken({ vapidKey: process.env.REACT_APP_vapidKey }).then((token) => {
        if (token) {
          setCurrentToken(token);
        }
      });
    }
  }, []);

  const submitDelivering = async () => {
    const data = {
      slot_id: props.delivery.slotId,
      crew_id: storeContainer.crewId,
      delivery_app_fcm_token: currentToken ?? null,
    };

    const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${shopId}/batch_delivering`;
    const auth = firebase.auth();

    setPosting(true);
    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status !== 200) {
            setMessageError(responseJson.error.message);
          }
          setPosting(false);
        })
        .catch((error) => {
          setMessageError(error.message);
          setPosting(false);
        });
    });
  };

  const submitDelivered = async () => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      title: `${shopShortName}`,
      message: '注文番号とスプーンがしっかり入っているか確認しましたか？',
      buttons: [
        {
          label: 'はい',
          onClick: async () => {
            const data = {
              slot_id: props.delivery.slotId,
              crew_id: storeContainer.crewId,
            };

            const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${shopId}/batch_delivered`;
            const auth = firebase.auth();

            setPosting(true);
            auth.currentUser!.getIdToken().then((token) => {
              fetch(apiEndPoint, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
              })
                .then(async (response) => {
                  const responseJson = await response.json();
                  if (response.status !== 200) {
                    setMessageError(responseJson.error.message);
                  }
                  setPosting(false);
                })
                .catch((error) => {
                  setMessageError(error.message);
                  setPosting(false);
                });
            });
          },
        },
        {
          label: 'いいえ',
          onClick: () => {},
        },
      ],
    });
  };

  const postDelayApi = async (delay: string, onClose: Function) => {
    const data = {
      slot_id: props.delivery.slotId,
    };

    const apiEndPoint = `${process.env.REACT_APP_api_server}/shops/${props.delivery.shopId}/report_boss?delay=${delay}`;
    const auth = firebase.auth();

    setPosting(true);
    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status !== 200) {
            setMessageError(responseJson.error.message);
          }
          setPosting(false);
        })
        .catch((error) => {
          setMessageError(error.message);
          setPosting(false);
        });
    });
    onClose();
  };

  const submitReportBoss = async () => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      title: `${shopShortName} 様`,
      message: '配達遅延報告しますか？',
      customUI: ({ onClose }) => (
        <div className="modal-dialog" style={{ color: 'black' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{shopShortName} 様</h5>
              <button type="button" className="close" aria-label="Close" onClick={() => onClose()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>配達遅延報告しますか？</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={async () => {
                  postDelayApi('short', onClose);
                }}
              >
                10分<span className="font-weight-bold">未満</span>遅延
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={async () => {
                  postDelayApi('long', onClose);
                }}
              >
                10分<span className="font-weight-bold">以上</span>遅延
              </button>
            </div>
          </div>
        </div>
      ),
    });
  };

  const status = props.delivery.orders.some((order) => !order.data.cooked_at) ? 'cooking' :
    props.delivery.orders.some((order) => !order.data.delivering) ? 'not_delivering' :
    props.delivery.orders.some((order) => !order.data.delivered_at) ? 'delivering' : 'delivered';

  const deliveryLocation = ((location) => {
    if (location) {
      return `${location.latitude},${location.longitude}`;
    }

    return '';
  })(shopData.location);

  const totalCurries = props.delivery.orders
    .map((order) => order.data.total_curries_in_this_order)
    .reduce((sum, element) => sum + element, 0);

  return (
    <>
      {messageError && <Alert message={messageError} color="danger" />}
      {slot && (
        <>
          <div className="row">
            <div className="col-auto mr-auto">
              <SlotTimeRange time={props.delivery.time} timeUntil={props.delivery.timeUntil} />
              &nbsp;
              <CookingStatusBadge cooked={status !== 'cooking'} />
            </div>
            <div className="col-auto">
              {status === 'cooking' ? (
                <>調理待ち</>
              ) :
              status === 'not_delivering' ? (
                <Button color="success" onClick={submitDelivering}>
                  <small>
                    出発 <i className="fas fa-check-circle fa-xs" />
                  </small>
                </Button>) :
                status === 'delivering' ? (
                <Button color="success" onClick={submitDelivered}>
                  <small>
                    配達完了 <i className="fas fa-check-circle fa-xs" />
                  </small>
                </Button>
                ) : (
                <span style={{ opacity: 0.5 }}>
                  配達完了 <i className="fas fa-check-circle fa-xs" />
                </span>
              )}
            </div>
          </div>
          <div className="mt-1">
            <div className="row">
              <div className="col">
                {props.delivery.orders
                  .sort((a, b) => (a.data.number > b.data.number ? 1 : -1))
                  .map((order) => (
                    <OrderNumberBadge key={order.id} order={order.data} />
                  ))}
              </div>
              <div className="col-auto">
                <small>🍛x</small>
                {totalCurries}
              </div>
            </div>
          </div>
          <table className="mt-1">
            <tbody>
              <tr>
                <th className="text-center align-baseline">
                  <i className="fas fa-building fa-xs" />
                </th>
                <td>
                  <ShopNameWithManualLink name={shopShortName} url={shopData.manual_url} />
                </td>
              </tr>
              <tr>
                <th className="text-center align-baseline">
                  <i className="fas fa-map-marked-alt fa-xs" />
                </th>
                <td>
                  <BlankLink url={`https://www.google.com/maps/search/?api=1&query=${deliveryLocation}`}>
                    <small>{shopData.address}</small>
                  </BlankLink>
                </td>
              </tr>
              {shopData.delivery_instruction && (
                <tr>
                  <th className="text-center align-baseline">
                    <i className="fas fa-comment fa-xs" />
                  </th>
                  <td>
                    <small>{shopData.delivery_instruction}</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="mt-3">
            <Button color="danger" disabled={slot.batch_delivery_boss_reported_at} onClick={submitReportBoss}>
              <small>配達遅延</small>
            </Button>
            {slot.batch_delivery_boss_reported_delay === 'long' && (
              <a href="tel:080-5653-7290" className="ml-2">
                <button type="button" className="btn btn-sm btn-danger" style={{ padding: '2px 4px' }}>
                  <small>社員に電話する</small>
                </button>
              </a>
            )}
          </div>
        </>
      )}
      <WindmillSpinnerOverlay loading={posting} />
    </>
  );
}

export default BatchDelivery;
