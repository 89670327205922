import React from 'react';
import { BootstrapColor } from './BootstrapColor';

const Button = (props: {
  color: BootstrapColor;
  disabled?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <button
    type="button"
    className={`btn btn-sm btn-${props.color}`}
    disabled={props.disabled ?? false}
    onClick={props.onClick}
    style={{ padding: '2px 4px' }}
  >
    {props.children}
  </button>
);

export default Button;
