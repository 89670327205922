import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from './firebase';
import { StoreContainer } from './store';

function SelectShop() {
  const history = useHistory();
  const [shops, setShops] = useState<any>();

  const storeContainer = StoreContainer.useContainer();

  useEffect(() => {
    firebase
      .firestore()
      .collection('shops')
      .orderBy('order')
      .get()
      .then((snap) => {
        const allShops = {};
        const selectableShops = {};
        snap.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          allShops[docSnapshot.id] = data;
          if (data.enabled && !data.kitchen_shop_id) {
            selectableShops[docSnapshot.id] = data;
          }
        });
        storeContainer.setShopData(allShops);
        setShops(selectableShops);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const change = (selected) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('店舗を切り替えます。よろしいですか？')) {
      storeContainer.setShopId(selected.target.value);
      history.push('/');
      window.location.reload();
    }
  };

  return (
    <select className="form-control form-control-sm form-select" value={storeContainer.shopId} onChange={change}>
      <option>店舗を選択</option>
      {shops === undefined
        ? ''
        : Object.keys(shops).map((shopId: string, i: number) => (
            <option key={shopId} value={shopId}>
              {shops[shopId].short_name}
            </option>
          ))}
    </select>
  );
}

export default SelectShop;
