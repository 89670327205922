import React, { ReactNode } from 'react';

const BlankLink = (props: { url?: string; children: ReactNode }) => (
  <a
    href={props.url}
    target="_blank"
    rel="noopener noreferrer"
    className="text-white"
    style={{ textDecoration: 'underline' }}
  >
    {props.children}
  </a>
);

export default BlankLink;
