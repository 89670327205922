import React from 'react';
import { BootstrapColor } from './BootstrapColor';

type Props = {
  message: string;
  color: BootstrapColor;
};

const Alert: React.FC<Props> = (props: Props) => <div className={`alert alert-${props.color}`}>{props.message}</div>;

export default Alert;
