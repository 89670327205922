import React, { useEffect, useState } from 'react';
import { Route, Switch  } from 'react-router-dom';
import Login from './Login';
import './style.css';
import withTMCAuth from './withTMCAuth';
import firebase from './firebase';
import { StoreContainer } from './store';
import SelectCrew from './SelectCrew';
import RoleContext from './RoleContext';
import Role from './Role';
import SlotSets from './SlotSets';

function Navigator(props: { user; signOut; error }) {
  const storeContainer = StoreContainer.useContainer();
  const [role, setRole] = useState<Role>(new Role([]));

  let interval;
  const resetTimeout = () => {
    if (interval) {
      clearTimeout(interval);
      setTimeout();
    }
  };

  const setTimeout = () => {
    interval = setInterval(() => {
      window.location.reload(); // this reload is to reload all html / javascript.
    }, 2 * 60 * 60 * 1000); // 2 hours
  };

  const watchEvents = ['mousedown', 'click', 'keypress'];

  const { user } = props;
  const { signOut } = props;

  for (const event of watchEvents) {
    window.addEventListener(event, resetTimeout);
  }

  useEffect(() => {
    setTimeout();

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (user) {
      if (user && user.providerData[0].providerId === 'google.com' && user.email.match(/@tokyomixcurry.com$/)) {
        setRole(new Role(['super-admin']));
      } else {
        try {
          const unregisterSlotsObserver = firebase
            .firestore()
            .collection('admins')
            .doc(user.uid)
            .onSnapshot(
              { includeMetadataChanges: true },
              (admin) => {
                if (admin.metadata.fromCache) {
                  // cache からの場合は画面を更新しない
                  return;
                }

                if (admin.exists) {
                  const { roles } = admin.data()!;
                  if (roles && roles.length > 0) {
                    setRole(new Role(roles));
                  } else {
                    setRole(new Role([]));
                    signOut();
                  }
                }
              },
              (err) => {
                setRole(new Role([]));
                console.log(err);
                signOut();
              },
            );

          return () => {
            unregisterSlotsObserver();
          };
        } catch (e) {
          signOut();
        }
      }
    }
    return () => {};
  }, [user, signOut]);


  return (
    <RoleContext.Provider value={role}>
      {user ? (
        <div id="navigator">
          {storeContainer.crewId ? (
            <Switch>
              <Route path="/" component={SlotSets} />
            </Switch>
          ) : (
            <>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-9 col-sm-12">
                    <SelectCrew />
                  </div>
                </div>
                <footer className="row justify-content-center">
                  <div className="col-lg-9 col-sm-12">
                    <button type="button" className="btn btn-link" onClick={props.signOut}>
                      Sign Out
                    </button>
                    {props.error ? <h1>{props.error.message}</h1> : null}
                  </div>
                </footer>
              </div>
            </>
          )}
        </div>
      ) : (
        <Login />
      )}
    </RoleContext.Provider>
  );
}

export default withTMCAuth(Navigator);
