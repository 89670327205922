import React from 'react';

const CookingStatusBadge = (props: { cooked: boolean }) => {
  const label = props.cooked ? '調理完了' : '調理待ち';
  const iconName = props.cooked ? 'check' : 'spinner';
  const backgroundColor = props.cooked ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.2)';

  return (
    <span className="badge ml-1" style={{ backgroundColor }}>
      {label}
      &nbsp;
      <i className={`fas fa-${iconName} fa-xs`} />
    </span>
  );
};

export default CookingStatusBadge;
