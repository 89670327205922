import React from 'react';
import firebase from '../firebase';
import Utils from '../Utils';

const OrderNumberBadge = (props: { order: firebase.firestore.DocumentData }) => {
  const totalCurries = props.order.total_curries_in_this_order;
  const backgroundColor = props.order.cooked_at ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.2)';

  return (
    <span className="badge mr-1" style={{ backgroundColor }}>
      {Utils.formatNumber(props.order)}
      {totalCurries > 1 ? `(x${totalCurries})` : ''}
    </span>
  );
};

export default OrderNumberBadge;
