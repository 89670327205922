import React from 'react';
import BlankLink from './BlankLink';

const ShopNameWithManualLink = (props: { name: string; url?: string }) => (
  <>
    {props.url ? (
      <BlankLink url={props.url}>
        <small>{props.name}</small>
      </BlankLink>
    ) : (
      <small>{props.name}</small>
    )}
  </>
);

export default ShopNameWithManualLink;
