import React from 'react';
import GoogleMapReact from 'google-map-react';
// eslint-disable-next-line no-unused-vars
import { IDelivery } from './IDelivery';
import { StoreContainer } from './store';
import Utils from './Utils';

const SlotDeliveryMap = (props: { deliveries: Array<IDelivery>; shop; handleClose }) => {
  const shopLocation = { lat: props.shop.location.latitude, lng: props.shop.location.longitude };
  const storeContainer = StoreContainer.useContainer();

  const getCoordinates = () =>
    new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        resolve(null);
      }
    });

  const handleApiLoaded = async (map, maps) => {
    try {
      const place: any = await getCoordinates();
      if (place) {
        // eslint-disable-next-line no-new
        new maps.Marker({
          position: { lat: place.coords.latitude, lng: place.coords.longitude },
          map,
          label: 'You',
        });
      }
    } catch (error) {
      // ignore error
    }

    // eslint-disable-next-line no-new
    const shopMarker = new maps.Marker({
      position: shopLocation,
      map,
      icon: {
        path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
        fillColor: '#B3CF37',
        fillOpacity: 0.6,
        strokeWeight: 2,
        scale: 0.8,
      },
      label: props.shop.short_name,
    });

    shopMarker.addListener('click', () => {
      window.open(`http://maps.google.co.jp/?q=${shopLocation.lat},${shopLocation.lng}`, '_blank');
    });

    for (const delivery of props.deliveries) {
      const icon = {
        path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
        fillColor: '#00FF00',
        fillOpacity: 0.6,
        strokeWeight: 2,
        scale: 0.8,
      };

      const office = storeContainer.shopData[delivery.shopId];

      if (office.location) {
        const location = {
          lat: office.location.latitude,
          lng: office.location.longitude,
        };

        // eslint-disable-next-line no-new
        const marker = new maps.Marker({
          position: location,
          map,
          icon,
          label: office.short_name,
        });

        ((url) => {
          marker.addListener('click', (a) => {
            window.open(url, '_blank');
          });
        })(`http://maps.google.co.jp/?q=${office.location.latitude},${office.location.longitude}`);
      }
    }
  };

  return (
    <div className="w-100 h-100">
      <button type="button" className="btn btn-dark btn-block" onClick={props.handleClose}>
        閉じる
      </button>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyDBHnjopFX1AG3i7MvAOCDoeUHuuXNGXwk',
        }}
        defaultCenter={shopLocation}
        defaultZoom={13.6} // 配達範囲の1.5kmがiPhone 6/7/8 (no plus)サイズで入るように
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      />
    </div>
  );
};

export default SlotDeliveryMap;
