import React, { useState, useEffect } from 'react';

function TimeLeft(props: { deadline: Date }) {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  const strTimeLeft = () => {
    let msg = '';
    if (timeLeft !== null) {
      if (timeLeft >= 0) {
        msg = `残り ${Math.ceil(timeLeft / 60)}分`;
      } else {
        msg = `${Math.ceil(-timeLeft / 60)}分超過`;
      }
    }
    return msg;
  };

  const badgeType = (left: number | null) => {
    let type = '';
    if (left === null) {
      type = '';
    } else if (left > 5 * 60) {
      // 5分以上時間が残っている場合は早すぎるので黒
      type = 'dark';
    } else if (left > 2 * 60) {
      // 5分以内2分前までが開始の青
      type = 'primary';
    } else if (left > 0) {
      // 残り2分で黄色
      type = 'warning';
    } else {
      // 時間を過ぎたら赤
      type = 'danger';
    }
    return type;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(Math.floor(props.deadline.getTime() / 1000 - Date.now() / 1000));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [props.deadline]);

  return (
    <>
      &nbsp;
      <span className={`small badge badge-${badgeType(timeLeft)}`}>{strTimeLeft()}</span>
    </>
  );
}

export default TimeLeft;
