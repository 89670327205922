import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Moment from 'react-moment';
import { Sticky, StickyContainer } from 'react-sticky';
import firebase from './firebase';
import { StoreContainer } from './store';
import SlotDeliveryMap from './SlotDeliveryMap';
import BatchDelivery from './BatchDelivery';
import { IDelivery } from './IDelivery';
import TimeLeft from './TimeLeft';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function SlotSet(props: { shopId: string; id: string; includeDoneByStaff: boolean; deliveryGroup: string | null }) {
  const storeContainer = StoreContainer.useContainer();
  const [mapOpened, setMapOpened] = useState<boolean>(false);
  const [deliveries, setDeliveries] = useState<Array<IDelivery>>();

  const deliveryPrioritySort = (a: IDelivery, b: IDelivery) => {
    // 法人デリバリー同士の場合は設定された優先順位順
    if (a.deliveryPriority < b.deliveryPriority) {
      return -1;
    }
    if (a.deliveryPriority > b.deliveryPriority) {
      return 1;
    }

    return 0;
  };

  useEffect(() => {
    let query = firebase
      .firestore()
      .collection('orders')
      .where('kitchen_shop_id', '==', props.shopId)
      .where('slot_set_id', '==', props.id)
      .where('status', '!=', 'canceled');

    if (!props.includeDoneByStaff) {
      query = query.where('done_by_staff', '==', false);
    }

    const unregisterOrdersObserver = query.onSnapshot((result) => {
      const records: Array<IDelivery> = [];

      for (const order of result.docs) {
        const orderData = order.data();
        if (orderData.batch_delivery) {
          const slotId = orderData.slot_id;

          if (!props.deliveryGroup || props.deliveryGroup === '__ALL__') {
            // nothing to do. show all
          } else {
            if (props.deliveryGroup !== '__NO__' && orderData.delivery_group?.id !== props.deliveryGroup) {
              continue;
            }

            if (props.deliveryGroup === '__NO__' && orderData.delivery_group?.id) {
              continue;
            }
          }

          let delivery = records.find((rec) => rec.shopId === orderData.shop_id && rec.slotId === slotId);

          if (!delivery) {
            delivery = {
              time: orderData.pickup_at.toDate(),
              timeUntil: orderData.pickup_until_at.toDate(),
              shopId: orderData.shop_id,
              slotId,
              deliveryPriority: (orderData.delivery_priority || 1) as number,
              orders: [],
            };
            records.push(delivery);
          }

          delivery.orders.push({
            id: order.id,
            data: orderData,
          });
        }
      }

      records.sort(deliveryPrioritySort);
      setDeliveries(records);
    });

    return () => {
      unregisterOrdersObserver();
    };
  }, [props.id, props.includeDoneByStaff, props.deliveryGroup, props.shopId]);

  let time: Date | null = null;
  let timeUntil: Date | null = null;

  if (deliveries && deliveries.length > 0) {
    time = deliveries.map((e) => e.time).reduce((a, b) => (a < b ? a : b));
    timeUntil = deliveries.map((e) => e.timeUntil).reduce((a, b) => (a < b ? b : a));
  }

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
    content: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0px',
    },
  };

  const onOpenModal = () => {
    setMapOpened(true);
  };

  const onCloseModal = () => {
    setMapOpened(false);
  };

  let status: string;
  let timeLeft: Date | null = null;

  if (deliveries?.some((delivery) => delivery.orders.some((order) => !order.data.cooked_at))) {
    status = 'preparing';
  } else if (deliveries?.some((delivery) => delivery.orders.some((order) => !order.data.delivering))) {
    status = 'waiting';
    const dueTimes =
      deliveries
        ?.map((delivery) => delivery.orders.map((a) => a.data.delivery_departure_due_time?.toDate()))
        .flat()
        .filter((a) => a) || [];
    timeLeft =
      dueTimes.reduce((a: Date, b: Date) => {
        return a > b ? a : b;
      }) || null;
  } else if (
    deliveries?.some((delivery) =>
      delivery.orders.some((order) => !order.data.done_by_staff && !order.data.delivered_at),
    )
  ) {
    status = 'delivering';
  } else {
    status = 'delivered';
  }

  const totalCurries = deliveries
    ?.flatMap((delivery) => delivery.orders.map((order) => order.data.total_curries_in_this_order))
    .reduce((sum, element) => sum + element, 0);

  return deliveries && deliveries.length > 0 ? (
    <StickyContainer
      className="card delivery-card text-white my-4"
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
    >
      <Sticky>
        {({ style }) => (
          <h6
            className="card-header align-middle"
            style={{
              ...style,
              backgroundColor: '#413535',
              borderBottom: '0.5px solid rgba(255, 255, 255, 0.1)',
              padding: '12px 8px',
              zIndex: 99,
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <a onClick={onOpenModal} className="text-white" style={{ textDecoration: 'underline' }}>
              {time && timeUntil && (
                <>
                  <Moment tz="Asia/Tokyo" format="HH:mm" date={time} />-
                  <Moment tz="Asia/Tokyo" format="HH:mm" date={timeUntil} />
                  &nbsp;
                </>
              )}
              配達枠地図
              <i className="fas fa-map fa-xs" />
            </a>
            <span style={{ marginLeft: '.2rem' }}>
              <small>🍛x</small>
              {totalCurries ?? '-'}
            </span>
            {
              // eslint-disable-next-line no-nested-ternary
              status === 'preparing' ? (
                <span style={{ float: 'right', opacity: 0.5 }}>配達準備中</span>
              ) : status === 'waiting' ? (
                <span style={{ float: 'right' }}>
                  出発待ち
                  {timeLeft && <TimeLeft deadline={timeLeft} />}
                </span>
              ) : status === 'delivering' ? (
                <span style={{ float: 'right' }}>配達中🚴</span>
              ) : (
                <span style={{ float: 'right' }}>配達完了👍</span>
              )
            }
          </h6>
        )}
      </Sticky>
      <div className="card-body" style={{ padding: '12px 8px' }}>
        {deliveries.map((delivery, deliveryIndex) => (
          <React.Fragment key={`${delivery.shopId}-${delivery.slotId}`}>
            <BatchDelivery delivery={delivery} />
            {deliveryIndex < deliveries.length - 1 && (
              <hr style={{ borderTop: '0.5px solid rgba(255, 255, 255, 0.1)' }} />
            )}
          </React.Fragment>
        ))}
      </div>
      <Modal
        isOpen={mapOpened}
        onRequestClose={() => {
          onCloseModal();
        }}
        contentLabel="Delivery map"
        style={customStyles}
      >
        <SlotDeliveryMap
          deliveries={deliveries}
          shop={storeContainer.shopData[storeContainer.shopId]}
          handleClose={() => {
            onCloseModal();
          }}
        />
      </Modal>
    </StickyContainer>
  ) : null;
}

export default SlotSet;
