import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { createRoot } from 'react-dom/client';
import Navigator from './Navigator';
import { StoreContainer } from './store';

const Index = () => {
  if (process.env.REACT_APP_error_reporting_key) {
    const errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
      key: process.env.REACT_APP_error_reporting_key as string,
      projectId: process.env.REACT_APP_projectId as string,

      service: 'delivery',

      // version: myServiceVersion,
      // Version identifier of the service reporting the error.

      // reportUncaughtExceptions: false
      // Set to false to prevent reporting unhandled exceptions, default: `true`.

      // reportUnhandledPromiseRejections: false
      // Set to false to prevent reporting unhandled promise rejections, default: `true`.

      // disabled: true
      // Set to true to not send error reports, this can be used when developing locally, default: `false`.

      // context: {user: 'user1'}
      // You can set the user later using setUser()
    });
  }

  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <StoreContainer.Provider>
          <Navigator />
        </StoreContainer.Provider>
      </QueryParamProvider>
    </Router>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Index />);
