import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import firebase from './firebase';
import { StoreContainer } from './store';

function SelectCrew() {
  const [crews, setCrews] = useState<any>();

  const storeContainer = StoreContainer.useContainer();

  useEffect(() => {
    const unregisterCrewsObserver = firebase
      .firestore()
      .collection('crews')
      .where('status', '==', 'active')
      .onSnapshot((snap) => {
        const records: any = [];
        snap.forEach((docSnapshot) => {
          records.push({
            value: docSnapshot.id,
            furigana: docSnapshot.data().furigana || '',
            display_name: docSnapshot.data().display_name || '',
          });
        });

        setCrews(records);
      });

    return () => {
      unregisterCrewsObserver();
    };
  }, [storeContainer.crewId]);

  const customFilter = (option, searchText) => {
    if (
      !searchText ||
      option.data.furigana.toLowerCase().match(`^${searchText.toLowerCase()}`) ||
      option.data.display_name.toLowerCase().match(`^${searchText.toLowerCase()}`)
    ) {
      return true;
    }
    return false;
  };

  const change = (value) => {
    storeContainer.setCrewId(value.value);
  };

  // form-control form-control-sm text-light bg-dark

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 20,
    }),
  };

  return (
    <Select
      styles={customStyles}
      value={storeContainer.crewId}
      options={crews}
      getOptionLabel={(option) => option.display_name}
      placeholder="クルー"
      filterOption={customFilter}
      onChange={change}
    />
  );
}

export default SelectCrew;
