import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const useStore = () => {
  const [shopId, setShopId] = useLocalStorage('shop_id', 'NA');
  const [shopData, setShopData] = useLocalStorage('shop_data', {});
  const [crewId, setCrewId] = useLocalStorage('crew_id', '');

  return {
    shopId,
    setShopId,
    shopData,
    setShopData,
    crewId,
    setCrewId,
  };
};

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const StoreContainer = createContainer(useStore);
