import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import firebase from './firebase';
import { StoreContainer } from './store';
import SlotSet from './SlotSet';
import SelectShop from './SelectShop';
import Picture from './Picture';

function SlotSets() {
  const [currentCrew, setCurrentCrew] = useState<any>();

  interface ISlotSet {
    id: string;
  }

  const [slotSets, setSlotSets] = useState<Array<ISlotSet>>();
  const [all, setAll] = useState(false);
  const [includeDoneByStaff, setIncludeDoneByStaff] = useState(false);

  const [deliveryGroups, setDeliveryGroups] = useState<Array<{ id: string, name:string }>>();
  const [selectedDelvieryGroup, setSelectedDelvieryGroup] =  useQueryParam('group', withDefault( StringParam, null));
  const storeContainer = StoreContainer.useContainer();
  const shopData = storeContainer.shopData[storeContainer.shopId];

  useEffect(() => {
    let unregisterCrewsObserver;
    if (storeContainer.crewId) {
      unregisterCrewsObserver = firebase
        .firestore()
        .collection('crews')
        .doc(storeContainer.crewId)
        .onSnapshot((snap) => {
          if (snap.exists) {
            setCurrentCrew(snap.data());
          } else {
            storeContainer.setCrewId('');
            setCurrentCrew(null);
          }
        });
    } else {
      setCurrentCrew(null);
    }
    return () => {
      if (unregisterCrewsObserver) {
        unregisterCrewsObserver();
      }
    };
  }, [storeContainer.crewId, storeContainer]);

  useEffect(() => {
    const collection = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('slot_sets')
      .where('time', '>', moment().tz('Asia/Tokyo').startOf('day').toDate());

    let query;
    if (all) {
      query = collection;
    } else {
      query = collection.where('time', '<', moment().tz('Asia/Tokyo').endOf('day').toDate());
    }

    const unregisterOrdersObserver = query.orderBy('time').onSnapshot({ includeMetadataChanges: true }, (snap) => {
      if (snap.metadata.fromCache) {
        // cache からの場合は画面を更新しない
        return;
      }

      const records: Array<ISlotSet> = [];
      const groups: Record<string, { id: string, name: string }> = {};
      snap.forEach((docSnapshot) => {
        records.push({
          id: docSnapshot.id,
        });

        if (docSnapshot.data().delivery_groups) {
          for (const deliveryGroup of docSnapshot.data().delivery_groups) {
            groups[deliveryGroup.id] = { id: deliveryGroup.id, name:deliveryGroup.name };
          }
        }
      });

      setDeliveryGroups(Object.values(groups));
      setSlotSets(records);
    });

    return () => {
      unregisterOrdersObserver();
    };
  }, [storeContainer.shopId, all, includeDoneByStaff, selectedDelvieryGroup ]);

  const changeDeliveryGroup = (e) => {
    const selectedValue = e.target.options[e.target.selectedIndex].value || null;
    setSelectedDelvieryGroup(selectedValue);
    if (selectedValue) {
      setSelectedDelvieryGroup(selectedValue);
    } else {
      setSelectedDelvieryGroup(null);
    }
  };

  const resetCrew = () => {
    if (window.confirm('サインアウトします。よろしいですか？')) {
      storeContainer.setCrewId(null);
    }
  };

  const openParentShopMap = () => {
    if (shopData.parking_location) {
      const url = `http://maps.google.co.jp/?q=${shopData.parking_location.latitude},${shopData.parking_location.longitude}`;
      window.open(url, '_blank');
    } else if (shopData.location) {
      const url = `http://maps.google.co.jp/?q=${shopData.location.latitude},${shopData.location.longitude}`;
      window.open(url, '_blank');
    } else {
      window.alert('店舗の位置が登録されていません');
    }
  };

  useEffect(() => {
    const message = firebase.messaging();
    if (!window.Notification) {
      return;
    }
    const requestPermissionAndGetToken = async () => {
      console.log('通知の許可を要求します...');
      const permission = await window.Notification.requestPermission();

      if (permission === 'granted') {
        console.log('通知が許可されました');
      } else {
        console.error('通知が許可されませんでした');
      }
    };

    if (window.Notification.permission === 'default') {
      requestPermissionAndGetToken();
    }

  }, [storeContainer.crewId]);

  return (
    <div id="orders" className="container">
      <header className="my-3">
        <div className="row my-2">
          <div className="col-auto">
            <img className="rounded" src="/images/logo.png" width="32" height="32" alt="" />
          </div>
          <div className="col">
            {currentCrew && (
              <div className="row align-items-center justify-content-end">
                <div className="col-auto pr-2">
                  <small>{currentCrew.display_name}</small>
                </div>
                <div className="col-auto pr-2 pl-0">
                  <Picture width={32} file={currentCrew.picture} alt="crew" />
                </div>
                <div className="col-auto pl-0">
                  <button type="button" className="btn btn-sm btn-outline-light" onClick={resetCrew}>
                    <i className="fas fa-sign-out-alt" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row my-2 mx-0">
          <div className="col-4 p-0">
            <SelectShop />
          </div>
          <div className="col-4 p-0 pl-1">
            {deliveryGroups && (
              <select
                className="form-control form-control-sm form-select"
                onChange={changeDeliveryGroup}
                defaultValue={selectedDelvieryGroup || ''}
              >
                <option value="">選択してください</option>
                {deliveryGroups.map((deliveryGroup) => (
                  <option key={deliveryGroup.id} value={deliveryGroup.id}>
                    {deliveryGroup.name}
                  </option>
                ))}
                <option value="__NO__">未設定</option>
                <option value="__ALL__">すべて</option>
              </select>
            )}
          </div>
          <div className="col-4 p-0 pl-1">
            {shopData && (
              <button
                type="button"
                className="btn btn-sm btn-outline-light"
                style={{ width: '100%' }}
                onClick={() => {
                  openParentShopMap();
                }}
              >
                🏡&nbsp;店舗に戻る
              </button>
            )}
          </div>
        </div>
        {window.Notification && window.Notification.permission === 'denied' && (
          <div className="alert alert-danger my-1 px-2 py-1" role="alert">
            <div className="d-flex flex-row">
            <small className="pr-2">
              <i className="fas fa-exclamation-triangle" />
            </small>
            <small style={{ whiteSpace: 'pre-wrap' }}>ブラウザの設定で通知をオンにしてください</small>
          </div>
        </div>)}
        {!window.Notification && (
          <div className="alert alert-danger my-1 px-2 py-1" role="alert">
            <div className="d-flex flex-row">
            <small className="pr-2">
              <i className="fas fa-exclamation-triangle" />
            </small>
            <small style={{ whiteSpace: 'pre-wrap' }}>通知がサポートされていないブラウザです</small>
          </div>
        </div>)}


        {storeContainer.shopId && selectedDelvieryGroup ? (
          <>
            {shopData?.delivery_notes && (
              <div className="alert alert-warning my-1 px-2 py-1" role="alert">
                <div className="d-flex flex-row">
                  <small className="pr-2">
                    <i className="fas fa-exclamation-triangle" />
                  </small>
                  <small style={{ whiteSpace: 'pre-wrap' }}>{shopData?.delivery_notes}</small>
                </div>
              </div>
            )}
          </>) :
            <div className="alert alert-danger" role="alert">
              店舗/デリバリーグループを選択してください
            </div>}
      </header>

      {storeContainer.shopId && selectedDelvieryGroup && (
        <>
        {slotSets === undefined ? (
          <div>Loading</div>
        ) : (
          slotSets.map((data) => (
            <SlotSet
              key={data.id}
              shopId={storeContainer.shopId}
              id={data.id}
              includeDoneByStaff={includeDoneByStaff}
              deliveryGroup={selectedDelvieryGroup}
            />
          ))
        )}

        <div className="row my-3">
          <div className="col-12">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="form-check-input"
                id="doneByStaffCheckBox"
                onChange={(e) => {
                  setIncludeDoneByStaff(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="doneByStaffCheckBox">
                配達完了の注文も表示
              </label>
            </div>
          </div>
          <div className="col-12">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="form-check-input"
                id="allCheckBox"
                onChange={(e) => {
                  setAll(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="allCheckBox">
                明日以降の注文も表示
              </label>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
}

export default SlotSets;
