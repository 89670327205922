import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

const SlotTimeRange = (props: { time: Date; timeUntil: Date }) => (
  <>
    <Moment tz="Asia/Tokyo" format="HH:mm" date={props.time} />
    -
    <Moment tz="Asia/Tokyo" format="HH:mm" date={props.timeUntil} />
  </>
);

export default SlotTimeRange;
